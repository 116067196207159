
import { ref, defineComponent, reactive } from 'vue'
// import { useNotification } from '@/composables'
import { formatTime } from '@/utils/dateTime'
import axios from '@/services/axios'
import TicketDetailDialog from './TicketDetailDialog.vue'

export default defineComponent({
  components: { TicketDetailDialog },
  setup() {
    const dialogVisible = ref(false)
    const isLoading = ref(true)
    // const { error, success } = useNotification()

    // const ticketParams = reactive<any>({
    //   limit: 10,
    //   page: 1,
    //   // search: null,
    //   // is_read: 'all',
    //   // orders: null,
    //   // _id: ticketId,
    // })

    const tickets = ref()

    const getTicketListOfOrder = async (orderId: string) => {
      if (orderId) {
        const params = {
          orders: orderId,
        }
        const baseResource = `orders/ticket/all`
        let res = await axios.get(baseResource, { params })
        if (res?.status === 200 || res?.status === 201) {
          tickets.value = res.data.data as any
        }
      }
    }

    const toggle = async (orderId: string) => {
      dialogVisible.value = true
      getTicketListOfOrder(orderId)
      isLoading.value = false
    }

    const onClose = () => {
      dialogVisible.value = false
    }

    const ticketDetaiDialog = ref<InstanceType<typeof TicketDetailDialog>>()
    const onViewDetailTicket = (id: string) => {
      ticketDetaiDialog.value?.toggle(id)
    }

    return {
      dialogVisible,
      ticketDetaiDialog,
      onViewDetailTicket,
      toggle,
      onClose,
      isLoading,
      tickets,
      formatTime,
      getTicketListOfOrder,
    }
  },
})
