
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'

import useOrderRepositories from '@/repositories/useOrderRepositories'
import useShopRepositories from '@/repositories/useShopRepositories'
import useSellerTeamRepositories from '@/repositories/useSellerTeamRepositories'
import { ElMessageBox } from 'element-plus'
import { useNotification } from '@/composables/useNotification'
import { catalogTypes } from '@/utils/catalog'
import {
  statusListAgency,
  addressTitleMap,
  hireDesignStatus,
  RequestSeller,
  RequestSellerStatusEnum,
} from '@/utils/constants'
import { getOrderStatusColor, getAddressStatusColor } from '@/utils/orderUtils'
import { shortcutsDatetimePicker } from '@/utils/dateTime'
import moment from 'moment'
import FilterTable from '@/components/common/FilterTable.vue'
import { ArrowDown } from '@element-plus/icons'
import { isLatteAgency } from '@/utils/agencyUtils'
import ImportFile from '@/pages/agency/orders/components/CancellationHelper/ImportFile.vue'
import HistoryDialog from '@/pages/agency/orders/components/CancellationHelper/HistoryDialog.vue'
import ImportHoldOrderFile from '@/pages/agency/orders/components/HoldOrderHelper/ImportFile.vue'
import HistoryHoldOrderDialog from '@/pages/agency/orders/components/HoldOrderHelper/HistoryDialog.vue'
import { AuthorityEnum } from '@/utils/userUtils'
import { UserTypes } from '@/utils/types'

export default defineComponent({
  components: {
    HistoryDialog,
    FilterTable,
    ArrowDown,
    ImportFile,
    ImportHoldOrderFile,
    HistoryHoldOrderDialog,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    dataFilter: {
      type: Object,
      default: () => ({}),
    },
    isAllowImportExport: {
      type: Boolean,
      default: true,
    },
    isOrderExporter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      exportOrders,
      exportDropshipOrders,
      exportFakeOrders,
      exportFkOrders,
    } = useOrderRepositories()
    const { error } = useNotification()

    const data = reactive({
      isExporting: false,
      isLoadingImport: false,
      isLoading: false,
      showExportHistory: false,
      dataHistories: {
        histories: ref<any[]>([]),
        total: 1,
        countTotal: 1,
        params: {
          page: 1,
          limit: 10,
        },
      },
      sellerTeams: ref<any[]>([]),
      stores: ref<any[]>([]),
      daterange: '',
    })

    // Filter Store
    const stores = ref<any[]>([])
    const { getAllStores } = useShopRepositories()
    const getAllStoresSellers = async (query?: string) => {
      if (query?.trim()) {
        let storeDatas = (await getAllStores(query)) as any
        stores.value = storeDatas
      }
    }

    // Filter Sellerteam
    const sellerTeams = ref<any[]>([])
    const { getAllSellerTeam } = useSellerTeamRepositories()
    const getSellerTeamList = async (query?: string) => {
      if (query?.trim()) {
        let sellerTeamDatas = (await getAllSellerTeam(query)) as any
        sellerTeams.value = sellerTeamDatas
      }
    }

    let allowUseCancellationHelper = ref(false)

    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }

    const showModalExport = async (type: string) => {
      try {
        ElMessageBox.confirm(
          `Export Fulfillment will export all ${type} order have Approved status and not yet exported. Confirm this action?`,
          'Export fulfillment orders',
          {
            confirmButtonText: 'Export',
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'primary',
            type: 'warning',
          }
        )
          .then(async () => {
            data.isExporting = true
            let res
            if (type === 'pod') {
              res = await exportOrders()
            } else if (type === 'drop') {
              res = await exportDropshipOrders()
            } else if (type === 'fake') {
              res = await exportFakeOrders()
            } else if (type === 'fk') {
              res = await exportFkOrders()
            }
            if (res?.url) {
              downloadFile('export.csv', res.url)
            } else {
              error('Error when export file')
            }
            data.isExporting = false
          })
          .catch(e => {
            console.log(e)
          })
      } catch (e) {
        error('Export order error!')
      }
    }

    // order
    const onFilterCallback = (data: any) => {
      let search = data?.search
      emit('changeParamsOrder', { key: 'search', value: search })
    }

    const orderName = ref('')
    const onOrderNameChange = () => {
      emit('changeParamsOrder', { key: 'orderName', value: orderName.value })
    }

    const transactionId = ref('')
    const onTransactionIdChange = () => {
      emit('changeParamsOrder', {
        key: 'transactionId',
        value: transactionId.value,
      })
    }

    const onChangeDate = async () => {
      if (data.daterange?.length > 1) {
        let startDate = moment(data.daterange[0]).format('YYYY-MM-DD')
        let endDate = moment(data.daterange[1]).format('YYYY-MM-DD')
        emit('changeDate', {
          startDate,
          endDate,
        })
      } else {
        emit('changeDate', {
          startDate: null,
          endDate: null,
        })
      }
    }

    const orderTypes = computed(() => {
      let defaultOrderTypes = [
        { label: 'Drop', value: 'drop' },
        { label: 'POD', value: 'pod' },
      ]
      if (isLatteAgency()) defaultOrderTypes.push({ label: 'FK', value: 'fk' })
      return defaultOrderTypes
    })

    const exportOrderTypes = computed(() => {
      let defaultExportOrderTypes = [
        { value: 'pod', title: 'POD' },
        { value: 'drop', title: 'DROP' },
      ]
      if (isLatteAgency())
        defaultExportOrderTypes.push({ value: 'fk', title: 'FK' })
      return defaultExportOrderTypes
    })

    const importFileDialog = ref<InstanceType<typeof ImportFile>>()
    const openImportFile = () => {
      importFileDialog.value?.toggle()
    }

    const historyDialog = ref<InstanceType<typeof HistoryDialog>>()
    const openHistoryDialog = () => {
      historyDialog.value?.toggle()
    }

    const importHoldOrderFileDialog =
      ref<InstanceType<typeof ImportHoldOrderFile>>()
    const openImportHoldOrderFile = () => {
      importHoldOrderFileDialog.value?.toggle()
    }

    const historyHoldOrderDialog = ref<InstanceType<typeof HistoryDialog>>()
    const openHistoryHoldOrderDialog = () => {
      historyHoldOrderDialog.value?.toggle()
    }

    onMounted(() => {
      const role = JSON.parse(localStorage.getItem('info') || '')?.authority
      if (role === AuthorityEnum.Accounting) {
        allowUseCancellationHelper.value = true
        return
      }
      allowUseCancellationHelper.value = false
    })

    return {
      props,
      data,
      showModalExport,
      onFilterCallback,
      catalogTypes,
      statusListAgency,
      getOrderStatusColor,
      getAddressStatusColor,
      addressTitleMap,
      onChangeDate,
      shortcutsDatetimePicker,
      hireDesignStatus,
      RequestSeller,
      RequestSellerStatusEnum,
      orderTypes,
      exportOrderTypes,
      importFileDialog,
      openImportFile,
      historyDialog,
      openHistoryDialog,
      allowUseCancellationHelper,
      importHoldOrderFileDialog,
      openImportHoldOrderFile,
      historyHoldOrderDialog,
      openHistoryHoldOrderDialog,
      stores,
      getAllStoresSellers,
      sellerTeams,
      getSellerTeamList,
      onOrderNameChange,
      orderName,
      transactionId,
      onTransactionIdChange,
    }
  },
  computed: {
    listAddressStatus() {
      const list = [
        { label: 'Invalid', value: 0 },
        { label: 'Valid', value: 1 },
        { label: 'Verified', value: 2 },
      ]
      try {
        const userInfo = JSON.parse(localStorage?.getItem('info') || '')
        if (userInfo?.userType === UserTypes.AGENCY)
          list.push({ label: 'Checking', value: 3 })
      } catch (error) {
        error
      }
      return list
    },
  },
})
