import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SemiSelect = _resolveComponent("SemiSelect")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Check = _resolveComponent("Check")!
  const _component_Close = _resolveComponent("Close")!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.props.scope.row.isDropship === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_icon, {
            size: 25,
            class: "font-bold text-info",
            title: "All order items have artworks"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SemiSelect)
            ]),
            _: 1
          })
        ]))
      : (_ctx.props.scope.row['designStatus'] === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_el_icon, {
              size: 25,
              class: "font-bold text-success",
              title: "All order items have artworks"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Check)
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_icon, {
              size: 25,
              class: "font-bold text-error",
              title: "All order items haven't artworks"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Close)
              ]),
              _: 1
            })
          ])),
    (_ctx.props.scope.row.metadata)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.props.scope.row.metadata.hire_design_status === 'assign')
            ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 0,
                size: "small",
                title: "Design status"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.designServiceTitleMap.get(
          _ctx.props.scope.row.metadata.hire_design_status
        )), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.props.scope.row.metadata.hire_design_status === 'in_review')
            ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 1,
                type: "warning",
                size: "small",
                title: "Design status"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.designServiceTitleMap.get(
          _ctx.props.scope.row.metadata.hire_design_status
        )), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.props.scope.row.metadata.hire_design_status === 'approved')
            ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 2,
                type: "success",
                size: "small",
                title: "Design status"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.designServiceTitleMap.get(
          _ctx.props.scope.row.metadata.hire_design_status
        )), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.props.scope.row.metadata.hire_design_status === 'rejected')
            ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 3,
                type: "danger",
                size: "small",
                title: "Design status"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.designServiceTitleMap.get(
          _ctx.props.scope.row.metadata.hire_design_status
        )), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}