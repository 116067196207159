
import { defineComponent } from 'vue'
import { designServiceTitleMap, } from '@/utils/constants'
import {
  Check,
  Close,
  SemiSelect,
} from '@element-plus/icons'

export default defineComponent({
  name: 'ColumnArtwork',
  components: {
    Check,
    Close,
    SemiSelect,
  },
  props: {
    scope: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    return {
      props,
      designServiceTitleMap
    }
  }
})
