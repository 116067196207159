
import { defineComponent } from 'vue'
import { addressTitleMap } from '@/utils/constants'
import { getAddressStatusColor } from '@/utils/orderUtils'

export default defineComponent({
  name: 'ColumnAddress',
  props: {
    scope: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      props,
      addressTitleMap,
      getAddressStatusColor
    }
  }
})
