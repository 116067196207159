import { ref, computed, reactive, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useFetchData } from "@/composables";
import { useServiceTicket } from '@/services/useServiceTicket'
import { useResource } from "@/services";
import axios from "@/services/axios";
import { AxiosResponse } from 'axios'

export default function ticketRepositories() {
  const resource = `orders/ticket`;
  const { get } = useResource(`${resource}/all`);

  const search = async (params : any)=>{
    try{
      const res = await axios.post(`${resource}/search`, params)
      return res;
    }catch (error) {
      console.log(error);
      return null;
    }
  }

  const addComment = async (params : any)=>{
    try {
      const res = await axios.post(`orders/ticket-conversation`, params)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }
  

  const getTickets = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData(`${resource}/all`, params);
      await fetchData();
      return documents.value;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const getEntity = async (id: string) => {
    const { get } = useResource(`${resource}/detail`);
    try {
      const { data, status } = await get(id);
      if (status === 200) {
        return { data, status } as AxiosResponse;
      }
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  const createTicket = async (params : any) => {
    try {
      const res = await axios.post(resource, params)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const updateTicket = async (params : any) =>{
    const { patch } = useResource(`${resource}/${params._id}`)
    try {
      const res = await patch(params)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const onUploadAttacments = async (file: any) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const res = await axios.post(`orders/ticket/upload`, formData)
      return res
    } catch (error) {
      return null
    }
  }

  const count = async () => {
    const { count } = useResource(resource)
    try {
      const { status, data } = await count()
      console.log(status, data)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  
  return {
    getTickets,
    getEntity,
    updateTicket,
    createTicket,
    onUploadAttacments,
    count,
    addComment,
    search,
    get
  }
  
}
