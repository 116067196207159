import { reactive, ref } from 'vue'
import { useResource } from '@/services'
import { IOrderWarehouse, Params ,IOrderVariant, IServiceResponse, IStatus, IClass, IOrderItem, IAction} from '@/interfaces/orders-warehouse'
import axios from '@/services/axios'
import {   IProductWarehouse } from '@/interfaces/product-warehouse'

import { IWarehouse } from '@/interfaces/warehouse'
class OrderDataService {
    private resource: {orders:string, catalogs?:string};
    public  state:  IStatus
    public ACTION: IAction;

    constructor(){
        this.resource = { 
            orders: "orders", 
            catalogs: "catalogs"
        }
        
        this.ACTION = {
            CREATE: 1,
            UPDATE:2,
            DETAIL: 3
        } 
        this.state = reactive<IStatus>({
            total: 0,
            isLoading: false,
            isSubmited: this.ACTION.CREATE,
            isSubmitedSku: this.ACTION.CREATE,
            isShowAddSku: false,
            isShowModalImport: false,
            orders: [] as IOrderWarehouse[], 
            catalogs: [] as any[],
            products: [] as IProductWarehouse[],
            product: {} as IProductWarehouse,
            warehouses:[] as IWarehouse[],
            order: {
                referenceId1:'',
                warehouseId:'',
                items: [] as IOrderItem[],

            },            
            orderItem: {
                productId:'',
                catalogId: '',
                variantId: '',
                variantTitle: '',
                catalogCode: '',
                quantity: 0,
                sku:''
            } as IOrderItem,
            params:{
                search:'',
                page: 1,
                limit: 50,
            }as Params,
            orderVariants:[] as IOrderVariant[],
            multipleSelection:[]
        })
    }
    async getAll(params: Params) : Promise<IServiceResponse>{
        const { getAll } = useResource(this.resource.orders);
        try {
            return await getAll(params) as IServiceResponse
        } catch (error) {
            return error as IServiceResponse
        }
    }
    async getOrderName(params: any = {}) : Promise<IServiceResponse>{
        const { fetch } = useResource(`${this.resource.orders}/manual/get-ordername`);
        try {
            return await fetch(params) as IServiceResponse
        } catch (error) {
            return error as IServiceResponse
        }
    }
    async create(order: IOrderWarehouse) {
        const { add } = useResource(`${this.resource.orders}/manual/create`);
        try {
            return await add(order) as IServiceResponse
        } catch (error ) {
            return error as IServiceResponse
        }
    }
    async update( order: IOrderWarehouse) {
        const { add } = useResource(`${this.resource.orders}/manual/update`);
        try {
            return await add(order) as IServiceResponse
        } catch (error ) {
            return error as IServiceResponse
        }
    }
    async getById(id: string | string[]) {
        const { get } = useResource(this.resource.orders);
        try {
            return await get(id) as IServiceResponse
        } catch (error) {
            return error as IServiceResponse
        }
    }
    async count(){
        try {
            const {  count } = useResource(this.resource.orders);
            return  await count() as IServiceResponse;
        } catch (error) {
            return error as IServiceResponse
        }
    }
    async countOrderByStatus(params: Params){
        try {
            const {  fetch } = useResource(`${this.resource.orders}/counter/status`);
            return  await fetch(params) as IServiceResponse;
        } catch (error) {
            return error as IServiceResponse
        }
    }
    async importOrdersFromCSV(file: any) : Promise<IServiceResponse>{
        try {
          const formData = new FormData()
          formData.append('file', file)
          const res = await axios.post(`orders/import/dropship-csv`, formData)
          return res
        } catch (error) {
            return error as IServiceResponse
        }
    }
}
export default new OrderDataService();
