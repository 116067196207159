
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ColumnCustomer',
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      props
    }
  }
})
