
import { defineComponent, ref, reactive, computed } from 'vue'
import { useNotification } from '@/composables'
import UploadFiles from '@/components/common/UploadFiles.vue'
import axios from '@/services/axios'
import { downloadFile } from '@/utils/file'
import { useStore } from 'vuex'

interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  preview?: string
  originFileObj?: any
  file: string | Blob
  raw: string | Blob
}

export default defineComponent({
  components: {
    UploadFiles,
  },
  setup(props, context) {
    let dialogVisible = ref(false)
    const resource = 'import'
    const url = resource
    const isShowTemplate = ref(true)
    const { error, success, warning } = useNotification()

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const state = reactive({
      isLoading: false,
    })

    const fileList = ref<FileItem[]>([])

    const onChangeFiles = (files: any) => {
      fileList.value = files
    }

    const onSubmitTracking = async () => {
      if (!fileList.value || fileList.value.length === 0) {
        warning('Please import your file!')
        return
      }
      let file = fileList.value[0].raw as any
      if (!file.name.endsWith('.xlsx')) {
        error('Wrong file! Please import xlsx file.')
        return
      }

      try {
        state.isLoading = true
        const formData = new FormData()
        formData.append('file', file)
        formData.append('importType', 'IMPORT_HOLD_ORDERS')
        const res = (await axios.post(resource, formData)) as any
        if (res?.status === 200 || res?.status === 201) {
          success('Import success!')
          close()
          context.emit('uploadFinish')
        } else {
          error(res.data.message)
        }
        fileList.value = []
      } catch (ex) {
        error('Error when import.')
      }
      state.isLoading = false
    }

    const { getters } = useStore()
    let config = computed(() => {
      return getters['app/appConfig']
    })

    const onDownloadTemplateTracking = () => {
      const url = config.value.templateFile.importFileToHold
      downloadFile(url)
    }

    const close = () => {
      dialogVisible.value = false
    }

    return {
      url,
      isShowTemplate,
      toggle,
      dialogVisible,
      onSubmitTracking,
      onChangeFiles,
      state,
      close,
      downloadFile,
      onDownloadTemplateTracking,
      getters,
    }
  },
})
