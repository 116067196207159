
import { defineComponent } from 'vue'
import { ref, reactive } from 'vue'
import { formatTime } from '@/utils/dateTime'
import axios from '@/services/axios'
import { useNotification } from '@/composables'
import { getOrderStatusColor } from '@/utils/orderUtils'

export default defineComponent({
  components: {},
  setup(props, context) {
    const { error, success } = useNotification()
    const dialogVisible = ref(false)

    const requestOrder = ref()

    const state = reactive({
      agencyNote: ref(''),
    })

    const toggle = (order: any) => {
      requestOrder.value = { ...order }

      // visible dialog
      dialogVisible.value = true
    }

    const userType = ref()
    let userInfo = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
    } catch (e) {
      return error("Can't find user info")
    }

    const updateAgencyNote = async (orderId: string) => {
      try {
        const resourceUpdate = 'orders/agency-note'
        const agencyNote = requestOrder.value?.agencyNote ?? ''
        const body = {
          agencyNote,
          orderId,
        }
        const res = await axios.put(resourceUpdate, body)
        if (res?.status === 200) {
          success('Update success!')
        }
      } catch (e) {
        console.log('updateAgencyNote - Error:', e)
      }
    }

    const confirm = async () => {
      const orderId = requestOrder.value?._id?.toString()

      // validate
      if (!orderId) {
        error('Not found order!')
      }
      const res: any = await updateAgencyNote(orderId)
      if (res?.status === 200 || res?.status === 201) {
        success('Update agency note success!')
      }
      context.emit('onAfterAgencyNote')
      close()
    }

    const clear = () => {
      state.agencyNote = ''
    }

    const close = () => {
      dialogVisible.value = false
    }

    return {
      close,
      toggle,
      dialogVisible,
      formatTime,
      requestOrder,
      state,
      confirm,
      clear,
      userInfo,
      userType,
      getOrderStatusColor,
      updateAgencyNote,
    }
  },
})
