
import { ref, defineComponent, reactive } from 'vue'
// import { useNotification } from '@/composables'
import { formatTime, formatShortDateMonthTime } from '@/utils/dateTime'
import ticketRepositories from '@/repositories/ticketRepositories'
import Editor from '@/pages/admin/catalog/components/Editor.vue'
import type { FormInstance } from 'element-plus'
import { getFileNameFromUrl, downloadFile } from '@/utils/file'

export default defineComponent({
  components: { Editor },
  setup() {
    const dialogVisible = ref(false)
    const isLoading = ref(true)
    let isDisableAddContent = ref(true)
    // const { error, success } = useNotification()
    const ruleFormRef = ref<FormInstance>()

    const dataTicket = reactive({
      isLoading: false,
      isLoading2: false,
      ticket: {
        _id: '',
        description: '',
        created_at: null,
        subject: '',
        requester: '',
        attachments: [],
        body_text: '',
        status: null,
        conversations: ref<any>([]),
      },
    })

    const tableRowClassName = ({
      row,
      rowIndex,
    }: {
      row: any
      rowIndex: number
    }) => {
      if (row.is_seller) {
        return 'success-row'
      }
      return ''
    }

    const changeDescription = (html: string, dataSet: any) => {
      dataSet.body_text = html
      if (html.length > 3) {
        isDisableAddContent.value = false
      } else {
        isDisableAddContent.value = true
      }
    }

    const { getEntity, addComment } = ticketRepositories()

    const fetchTicket = async (ticketId: string) => {
      const res = await getEntity(ticketId)
      if (res && res.data) {
        const initialConversation = [
          {
            body: res.data.description,
            is_seller: true,
            full_name: res.data.requester_id.fullName,
            updated_at: res.data.updated_date,
            email: res.data.requester_id.email,
          },
        ]
        dataTicket.ticket._id = res?.data?._id
        dataTicket.ticket.status = res.data.status
        dataTicket.ticket.subject = res?.data?.subject
        dataTicket.ticket.attachments = res?.data?.attachments
        dataTicket.ticket.requester = res.data.requester_id.fullName
          ? res.data.requester_id.fullName
          : res.data.requester_id.email
        dataTicket.ticket.conversations = [
          ...initialConversation,
          ...res?.data?.conversations,
        ]
      }
    }

    const toggle = async (id: string) => {
      dialogVisible.value = true
      fetchTicket(id)
      isLoading.value = false
    }

    const onClose = () => {
      dialogVisible.value = false
    }

    const addCommentTicket = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      const formData = {
        ticket: dataTicket.ticket._id,
        body: dataTicket.ticket.body_text,
      }
      dataTicket.isLoading = true
      await addComment(formData)
      await fetchTicket(dataTicket.ticket._id)
      dataTicket.isLoading = false
      dataTicket.ticket.body_text = ''
    }

    return {
      dialogVisible,
      toggle,
      onClose,
      isLoading,
      formatTime,
      tableRowClassName,
      formatShortDateMonthTime,
      changeDescription,
      isDisableAddContent,
      dataTicket,
      fetchTicket,
      ruleFormRef,
      addCommentTicket,
      getFileNameFromUrl,
      downloadFile,
    }
  },
})
