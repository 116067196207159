
import { defineComponent } from 'vue'
import { getWarningContent } from '@/utils/orderUtils'
import { formatTime } from '@/utils/dateTime'
import { Warning } from '@element-plus/icons'
export default defineComponent({
  name: 'ColumnTitle',
  components: {
    Warning,
  },
  props: {
    scope: {
      type: Object,
      required: true,
    },
    isOrderExporter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      props,
      formatTime,
      getWarningContent,
    }
  },
})
