
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useRoute, useRouter } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'
import { imageUrl } from '@/utils/image'
import useSellerTeamRepositories from '@/repositories/useSellerTeamRepositories'
import useShopRepositories from '@/repositories/useShopRepositories'
import { ElMessageBox } from 'element-plus'
import { formatDateTime, formatTime } from '@/utils/dateTime'
import Pagination from '@/components/common/Pagination.vue'
import { ArrowDown, Message } from '@element-plus/icons'
import OrderImagePreview from '@/components/common/OrderImagePreview.vue'
import { formatterDollars } from '@/utils/formatter'
import { isManagerOrAccounting, validateOrderAction } from '@/utils/orderUtils'
import { ADDRESS_STATUS } from '@/utils/constants'
import OrderDataService from '@/services/orderProductService'
import { UserTypes } from '@/utils/types'
import TicketListDialog from '@/pages/agency/tickets/components/TicketListDialog.vue'
import RequestSellerDialog from './components/RequestSellerDialog.vue'
import AgencyNoteDialog from './components/AgencyNoteDialog.vue'
import ticketRepositories from '@/repositories/ticketRepositories'
import { RequestSeller } from '@/utils/constants'
import DataFilterArea from './components/DataFilterArea.vue'
import ColumnTitle from './components/TableRow/ColumnTitle.vue'
import ColumnCustomer from './components/TableRow/ColumnCustomer.vue'
import ColumnSource from './components/TableRow/ColumnSource.vue'
import ColumnArtwork from './components/TableRow/ColumnArtwork.vue'
import ColumnExport from './components/TableRow/ColumnExport.vue'
import ColumnSellerTeam from './components/TableRow/ColumnSellerTeam.vue'
import ColumnActions from './components/TableRow/ColumnActions.vue'
import ColumnStatus from './components/TableRow/ColumnStatus.vue'
import ColumnAddress from './components/TableRow/ColumnAddress.vue'
import ColumnTracking from './components/TableRow/ColumnTracking.vue'
interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  preview?: string
  originFileObj?: any
  file: string | Blob
  raw: string | Blob
}

export default defineComponent({
  components: {
    sectionLayoutContent,
    Pagination,
    OrderImagePreview,
    ArrowDown,
    Message,
    TicketListDialog,
    RequestSellerDialog,
    AgencyNoteDialog,
    DataFilterArea,
    ColumnTitle,
    ColumnCustomer,
    ColumnSource,
    ColumnArtwork,
    ColumnExport,
    ColumnSellerTeam,
    ColumnActions,
    ColumnStatus,
    ColumnAddress,
    ColumnTracking,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const { success, error, warning } = useNotification()
    const isAllowImportExport = ref<boolean>(true)
    const isAllowActions = ref<boolean>(true)
    const isOrderExporter = ref(false)

    let userInfo = undefined

    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
      isAllowImportExport.value = isManagerOrAccounting(userInfo)
      isAllowActions.value = isAllowImportExport.value
    } catch (e) {
      return error("Can't find user info")
    }

    let isShowModalImport = ref<boolean>(false)
    const breadcrumbs = ['Orders', '']

    const titleImport = 'Export History'
    const titleExport = 'Export Orders'
    const titleReport = 'Report Orders'

    const iconExport = 'far fa-file-excel'
    const iconImport = 'fas fa-history'
    const iconReport = 'fas fa-chart-pie'

    const content = computed(() => {
      const extraActions = {
        breadcrumbs,
        titleExport: isAllowImportExport.value ? titleExport : '',
        iconExport,
      }
      if (isOrderExporter.value) return extraActions
      return {
        ...extraActions,
        titleImport: isAllowImportExport.value ? titleImport : '',
        titleReport: isAllowImportExport.value ? titleReport : '',
        iconImport,
        iconReport,
      }
    })

    const fileList = ref<FileItem[]>([])
    const data = reactive({
      isLoadingExport: false,
      isLoadingExportDropship: false,
      isLoadingImport: false,
      isLoading: false,
      showExportHistory: false,
      dataHistories: {
        histories: ref<any[]>([]),
        total: 1,
        countTotal: 1,
        params: {
          page: 1,
          limit: 10,
        },
      },
      sellerTeams: ref<any[]>([]),
      stores: ref<any[]>([]),
      daterange: '',
    })

    const {
      getOrders,
      state,
      approveOrder,
      rejectOrder,
      cancelOrders,
      agencyUnHoldOrders,
      ignoreCannotShipOrder,
      shipmentCancelOrder,
      exportOrder,
      exportOrders,
      exportDropshipOrders,
      exportFakeOrders,
      verifyAddressOrders,
      exportHistories,
      reportOrder,
    } = useOrderRepositories()

    let params = reactive<any>({
      search: route.query.search || null,
      orderName: route.query.orderName || null,
      transactionId: route.query.transactionId || null,
      status: route.query.status || null,
      source: route.query.source || null,
      addressValid: route.query.addressValid || null,
      exportStatus: route.query.exportStatus || null,
      sellerTeam: route.query.sellerTeam || null,
      orderDate: {
        startDate: route.query.startDate || null,
        endDate: route.query.endDate || null,
        timezone: route.query.timezone || null,
      },
      isUseLattePayment: route.query.isUseLattePayment || null,
      orderType: route.query.orderType || null,
      store: route.query.store || null,
      catalogType: route.query.catalogType || null,
      fulfillmentWarning: route.query.fulfillmentWarning || null,
      hireDesignStatus: route.query.hireDesignStatus || null,
      designStatus: route.query.designStatus || null,
      requestSellerReasonCode: route.query.requestSellerReasonCode || null,
      limit: route.query.limit || 10,
      page: route.query.page || 1,
    })

    const removeNullProperties = (object: any) => {
      for (const [key, value] of Object.entries(object)) {
        if (value === null || value === undefined || value === '') {
          delete object[key]
        }
      }
      return object
    }

    const updateURL = () => {
      params = removeNullProperties(params)
      router.push({
        query: {
          ...params,
          startDate: params.orderDate.startDate || null,
          endDate: params.orderDate.endDate || null,
          timezone: params.orderDate.timezone || null,
        },
      })
    }

    const fetchOrders = async (params: any) => {
      data.isLoading = true
      try {
        updateURL()
        await getOrders(params)
      } catch (e) {
        console.log(e)
      } finally {
        data.isLoading = false
      }
    }

    watch(params, fetchOrders, { deep: true })

    const handleSelectionChange = (val: any) => {
      state.multipleSelection = val
    }
    const columnTemp = reactive([
      {
        key: 'name',
        title: 'Order',
        dataIndex: 'orderName',
        minWidth: '12',
        slots: { title: 'customTitle', customRender: 'orderName' },
      },
      {
        title: 'Customer',
        dataIndex: 'fullName',
        minWidth: '10',
      },
      {
        title: 'Address',
        dataIndex: 'addressValid',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'FFM status',
        dataIndex: 'status',
        minWidth: '8',
        align: 'center',
      },
      {
        title: 'Source',
        dataIndex: 'source',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Type',
        dataIndex: 'orderType',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Artwork',
        dataIndex: 'designStatus',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Export',
        dataIndex: 'exportStatus',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'FFM Cost',
        dataIndex: 'sellerAmount',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Price',
        dataIndex: 'grandTotal',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Seller',
        dataIndex: 'sellerTeam',
        minWidth: '8',
      },
      {
        title: 'Tracking',
        dataIndex: 'trackingNumber',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        minWidth: '7',
        align: 'center',
      },
    ])

    const columns = computed(() => {
      if (isOrderExporter.value) {
        const temp = columnTemp.filter(
          (item: any) =>
            item.dataIndex !== 'sellerTeam' && item.dataIndex !== 'grandTotal'
        )
        return temp
      }
      return columnTemp
    })

    const orderTypeComputed = (order: any) => {
      return order?.isFK === true
        ? 'FK'
        : order?.isDropship === true
        ? 'Drop'
        : 'POD'
    }

    const onChangeDate = async (value: any) => {
      params.orderDate = {
        startDate: value.startDate,
        endDate: value.endDate,
        timezone: 'America/Los_Angeles',
      }
    }

    const { getAllSellerTeam } = useSellerTeamRepositories()
    const getSellerTeamList = async () => {
      let res = await getAllSellerTeam()
      data.sellerTeams = res as any[]
    }

    const { getAllStores } = useShopRepositories()
    const getAllStoresSellers = async () => {
      let stores = await getAllStores()
      data.stores = stores as any[]
    }

    onMounted(async () => {
      onLoadOrders()
      isOrderExporter.value = localStorage.getItem('isOrderExporter') === 'true'
      if (!isOrderExporter.value) {
        // getSellerTeamList()
        // getAllStoresSellers()
      }
    })

    const mergeById = (a1: any[], a2: any[]) => {
      return a1.map(itm => ({
        ...a2.find(item => item._id === itm._id && item),
        ...itm,
      }))
    }

    const countTicketOrders = async () => {
      const { search } = ticketRepositories()
      let arrOrderId = []
      if (state.orders && state.orders.length > 0) {
        arrOrderId = state.orders?.map((s: any) => s._id)
      }
      if (arrOrderId.length > 0) {
        const res = await search(arrOrderId)
        if (res && res?.data.length > 0) {
          state.orders = mergeById(state.orders, res.data)
        }
      }
    }

    const onLoadOrders = async () => {
      data.isLoading = true
      await getOrders(params)
      countTicketOrders()
      data.isLoading = false
    }

    const confirmExportOrder = async () => {
      ElMessageBox.confirm(
        `Confirm to export ${state.total} orders?`,
        'Warning',
        {
          confirmButtonText: 'Export',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async () => {
        await exportDataOrders()
      })
    }

    const reportOrders = async () => {
      ElMessageBox.confirm(
        `Confirm to report ${state.total} orders?`,
        'Warning',
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async () => {
        data.isLoadingExport = true
        const res = (await reportOrder(params)) as any
        data.isLoadingExport = false
        window.open(res?.data?.url, '_blank')
        success(res?.data?.message || `Export order success`)
      })
    }

    const exportDataOrders = async () => {
      try {
        data.isLoadingExport = true
        const res = (await exportOrder(params)) as any
        data.isLoadingExport = false
        window.open(res?.data?.url, '_blank')
        success(res?.data?.message || `Export order success`)
      } catch (e) {
        error()
        //
      }
      //
    }

    const {
      validateApproved,
      validateReject,
      validateCancelShip,
      validateVerifyAddress,
      validateAgencyCancel,
      validateAgencyUnHold,
      ALLOW_STATUS_WITH_ACTION,
    } = validateOrderAction()

    const checkSelectOrderForAction = (action: string) => {
      let filterSelected = []
      if (action === 'approve') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateApproved(item.status))
      } else if (action === 'reject') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateReject(item.status))
      } else if (action === 'cancel-ship') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateCancelShip(item.status))
      } else if (action === 'verify-address') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(
            item =>
              validateVerifyAddress(item.status) &&
              item.addressValid === ADDRESS_STATUS.US_INVALID
          )
      } else if (action === 'cancel') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateAgencyCancel(item.status))
      } else if (action === 'shipping-area') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => item.fulfillmentWarning === 'not_shipping_country')
      } else if (action === 'unhold') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateAgencyUnHold(item.status))
      }
      return filterSelected?.length > 0
    }

    const onApproveOrder = async () => {
      data.isLoading = true
      try {
        const selectedOrders = state.multipleSelection.map((item: any) => item)
        const filterdedOrders = selectedOrders.filter(item =>
          validateApproved(item.status)
        )
        if (filterdedOrders?.length > 0) {
          const idsSelected = filterdedOrders.map((item: any) => item._id)
          onSubmitApprove(idsSelected)
        } else {
          warning('No orders to update. Order status must be In Review')
          return
        }
      } catch (e) {
        error('Error')
      }
      data.isLoading = false
    }

    const verifyAddressOrder = async (ids: any[]) => {
      try {
        const res = (await verifyAddressOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Verify Address order success!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const onRejectOrder = async () => {
      data.isLoading = true
      try {
        const selectedOrders = state.multipleSelection.map((item: any) => item)
        const filterdedOrders = selectedOrders.filter(item =>
          validateReject(item.status)
        )
        if (filterdedOrders?.length > 0) {
          const idsSelected = filterdedOrders.map((item: any) => item._id)
          onSubmitReject(idsSelected)
        } else {
          warning(
            'No orders to update. Order status must be In Review or Approved'
          )
          return
        }
      } catch (e) {
        error('Error')
      }
      data.isLoading = false
    }

    const onSubmitCancelShip = async (ids?: any[]) => {
      data.isLoading = true
      try {
        ElMessageBox.prompt('Input cancel reason', 'Cancel order', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        }).then(async ({ value }) => {
          if (!ids?.length) {
            ids = state.multipleSelection.map((order: any) => order._id)
          }
          let { data, status } = await shipmentCancelOrder({
            ids: ids,
            content: value,
          })
          if (status === 200 || status === 201) {
            success('Shipment cancel success!')
            onLoadOrders()
          } else {
            error(data.message)
          }
        })
      } catch (err) {
        error()
      }
      data.isLoading = false
    }

    const onCancelShipOrder = async () => {
      data.isLoading = true
      try {
        const selectedOrders = state.multipleSelection.map((item: any) => item)
        const filterdedOrders = selectedOrders.filter(item =>
          validateReject(item.status)
        )
        if (filterdedOrders?.length > 0) {
          const idsSelected = filterdedOrders.map((item: any) => item._id)
          onSubmitCancelShip(idsSelected)
        } else {
          warning('No orders to update. Order status must be In Production')
          return
        }
      } catch (e) {
        error('Error')
      }
      data.isLoading = false
    }

    const onSubmitApprove = async (ids: any[]) => {
      data.isLoading = true
      try {
        const res = await approveOrder({
          ids: ids,
        })
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Approve order success!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
      data.isLoading = false
    }

    const onSubmitReject = async (ids?: any[]) => {
      data.isLoading = true
      try {
        ElMessageBox.prompt('Input reject reason', 'Reject order', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        }).then(async ({ value }) => {
          if (!ids?.length) {
            ids = state.multipleSelection.map((order: any) => order._id)
          }
          let { data, status } = await rejectOrder({
            ids: ids,
            content: value,
          })
          if (status === 200 || status === 201) {
            success('Reject order success!')
            onLoadOrders()
          } else {
            error(data.message)
          }
        })
      } catch (err) {
        error()
      }
      data.isLoading = false
    }

    const onIgnoreCheckShipSelectedOrders = async () => {
      data.isLoading = true
      try {
        const selectedOrders = state.multipleSelection.map((item: any) => item)
        const filterdedOrders = selectedOrders
          .map((item: any) => item)
          .filter(item => item.fulfillmentWarning === 'not_shipping_country')
        if (filterdedOrders?.length === 0) {
          warning('No orders to update.')
          return
        }

        const ids = filterdedOrders.map((item: any) => item._id)
        const isIgnoreCannotShip = true
        const res = await ignoreCannotShipOrder({
          ids,
          isIgnoreCannotShip,
        })
        state.multipleSelection = []
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Success`)
          onLoadOrders()
        } else {
          error('Error in server')
          console.log('onIgnoreCheckShipSelectedOrders - error', res)
        }
      } catch (e) {
        error()
      }
      data.isLoading = false
    }

    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }

    const showModalExport = async (type: string) => {
      try {
        ElMessageBox.confirm(
          `Export Fulfillment will export all ${type} order have Approved status and not yet exported. Confirm this action?`,
          'Export fulfillment orders',
          {
            confirmButtonText: 'Export',
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'primary',
            type: 'warning',
          }
        )
          .then(async () => {
            data.isLoading = true
            let res
            if (type === 'pod') {
              res = await exportOrders()
            } else if (type === 'drop') {
              res = await exportDropshipOrders()
            } else if (type === 'fake') {
              res = await exportFakeOrders()
            }
            if (res?.url) {
              downloadFile('export.csv', res.url)
            } else {
              error('Error when export file')
            }
            onLoadOrders()
            data.isLoading = false
          })
          .catch(e => {
            data.isLoading = false
            console.log(e)
          })
      } catch (e) {
        error('Export order error!')
      }
    }

    const onLoadHistories = async () => {
      try {
        let res = await exportHistories(data.dataHistories.params)
        if (res) {
          data.dataHistories.histories = res.documents.value as any[]
          data.dataHistories.total = res.total.value as number
        }
      } catch (error) {
        console.log(error)
      }
    }

    watch(data.dataHistories.params, () => {
      onLoadHistories()
    })

    const showExportHistory = async () => {
      try {
        data.showExportHistory = true
        onLoadHistories()
      } catch (error) {
        console.log(error)
      }
    }

    const onFilterCallback = (data: any) => {
      params[data?.key] = data?.value
    }

    const countOrderByStatus = ref<any>()
    const getCountOrderByStatus = async (): Promise<void> => {
      try {
        const res = await OrderDataService.countOrderByStatus(params)
        countOrderByStatus.value =
          res.status === 200
            ? res.data.reduce(
                (o: any, c: any) => ({ ...o, [c._id]: c.count }),
                {}
              )
            : {}
      } catch (e) {
        error('')
      }
    }

    const isDisplayCountOrderByStatus = ref(false)

    const onChangeDisplayCount = async () => {
      if (!countOrderByStatus.value) {
        await getCountOrderByStatus()
      }
      isDisplayCountOrderByStatus.value = !isDisplayCountOrderByStatus.value
    }

    const onVerifyAddressSelectedOrders = async () => {
      try {
        const ids = state.multipleSelection.map((item: any) => item._id)
        if (ids?.length === 0) {
          warning('No orders to update.')
          return
        }
        const { data }: any = await verifyAddressOrders({ ids })
        state.multipleSelection = []
        onLoadOrders()
        success(data?.message || 'Success')
      } catch (e: any) {
        console.log(e)
        error(e?.message)
      }
    }

    const onCancelSelectedOrders = async () => {
      try {
        const ordersSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateAgencyCancel(item.status))
        if (ordersSelected?.length === 0) {
          warning('No orders to update.')
          return
        }
        const res = (await cancelOrders({
          ids: state.multipleSelection.map((item: any) => item._id),
        })) as any
        state.multipleSelection = []
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Success`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (e) {
        error()
      }
    }

    const onUnholdSelectedOrders = async () => {
      try {
        const ordersSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateAgencyUnHold(item.status))
        if (ordersSelected?.length === 0) {
          warning('No orders to update.')
          return
        }
        console.log('ordersSelected', ordersSelected)
        const res = (await agencyUnHoldOrders({
          ids: state.multipleSelection.map((item: any) => item._id),
        })) as any
        state.multipleSelection = []
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Success`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (e) {
        error()
      }
    }

    const onOpenMessageWarningAction = async (action: string) => {
      let statusList = ''
      let actionName = ''
      if (action === 'approve') {
        statusList = ALLOW_STATUS_WITH_ACTION.AGENCY_APPROVE
        actionName = 'Approve Orders'
      } else if (action === 'reject') {
        statusList = ALLOW_STATUS_WITH_ACTION.AGENCY_REJECT
        actionName = 'Reject Orders'
      } else if (action === 'cancel-ship') {
        statusList = ALLOW_STATUS_WITH_ACTION.AGENCY_CANCEL_SHIP
        actionName = 'Cancel Ship Orders'
      } else if (action === 'verify-address') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_VERIFY_ADDRESS
        actionName = 'Verify address'
      } else if (action === 'cancel') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_CANCEL
        actionName = 'Cancel Orders'
      } else if (action === 'shipping-area') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_VERIFY_ADDRESS
        actionName = 'Ignore Shipping Area Check'
      } else if (action === 'unhold') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_UNHOLD
        actionName = 'Unhold Orders'
      }
      ElMessageBox.confirm(
        `Order with status <b>${statusList}</b> will update.<br> Continue ${actionName}?`,
        `${actionName}`,
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'primary',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          if (action === 'approve') {
            onApproveOrder()
          } else if (action === 'reject') {
            onRejectOrder()
          } else if (action === 'cancel-ship') {
            onCancelShipOrder()
            // warning('cancel-ship')
          } else if (action === 'verify-address') {
            onVerifyAddressSelectedOrders()
          } else if (action === 'cancel') {
            onCancelSelectedOrders()
          } else if (action === 'shipping-area') {
            onIgnoreCheckShipSelectedOrders()
          } else if (action === 'unhold') {
            onUnholdSelectedOrders()
          }
        })
        .catch(() => {
          // ElMessage({
          //   type: 'info',
          //   message: 'Update canceled',
          // })
        })
    }

    const ticket = reactive({
      form: {
        status: 2,
        description: '',
        orders: '',
        subject: '',
        category: '',
        subcategory: '',
        prefer_solution: '',
        product_type: '',
        custom_fields: {},
      },
    })

    const ticketListDialog = ref<InstanceType<typeof TicketListDialog>>()
    const onViewTicketList = (order: any) => {
      const orderId = order._id
      ticketListDialog.value?.toggle(orderId)
    }

    const requestSellerDialog = ref<InstanceType<typeof RequestSellerDialog>>()
    const onClickRequestSeller = (order: any) => {
      requestSellerDialog.value?.toggle(order)
    }

    const agencyNoteDialog = ref<InstanceType<typeof AgencyNoteDialog>>()
    const onClickAgencyNote = (order: any) => {
      agencyNoteDialog.value?.toggle(order)
    }

    const onAfterResquestSeller = () => {
      getOrders(params)
    }

    const onAfterAgencyNote = () => {
      getOrders(params)
    }

    const findSellerTeam = (data: any[], id: string) => {
      return data.find((s: any) => s.id === id) || {}
    }

    return {
      findSellerTeam,
      onAfterAgencyNote,
      agencyNoteDialog,
      onClickAgencyNote,
      onAfterResquestSeller,
      onClickRequestSeller,
      requestSellerDialog,
      onViewTicketList,
      countTicketOrders,
      ticketListDialog,
      ticket,
      onOpenMessageWarningAction,
      getCountOrderByStatus,
      countOrderByStatus,
      isShowModalImport,
      content,
      columns,
      state,
      data,
      currentPage: ref(1),
      fileList,
      router,
      params,
      formatterDollars,
      formatDateTime,
      imageUrl,
      exportDataOrders,
      showModalExport,
      showExportHistory,
      handleSelectionChange,
      onSubmitApprove,
      onSubmitReject,
      onChangeDate,
      onFilterCallback,
      onApproveOrder,
      onRejectOrder,
      formatTime,
      isDisplayCountOrderByStatus,
      onChangeDisplayCount,
      validateOrderAction,
      validateApproved,
      validateReject,
      validateCancelShip,
      checkSelectOrderForAction,
      onSubmitCancelShip,
      onCancelShipOrder,
      confirmExportOrder,
      userInfo,
      UserTypes,
      onVerifyAddressSelectedOrders,
      getSellerTeamList,
      useShopRepositories,
      getAllStores,
      getAllStoresSellers,
      verifyAddressOrder,
      validateVerifyAddress,
      ADDRESS_STATUS,
      validateAgencyCancel,
      cancelOrders,
      RequestSeller,
      reportOrders,
      onLoadOrders,
      isAllowImportExport,
      isAllowActions,
      orderTypeComputed,
      isOrderExporter,
    }
  },
})
