
import { defineComponent } from 'vue'
import { getTrackingOfOrder, } from '@/utils/orderUtils'
import { copyToClipboardV2 } from '@/utils/string'
import { CopyDocument } from '@element-plus/icons'
import { Check } from '@element-plus/icons'
export default defineComponent({
  name: 'ColumnTracking',
  components: {
    CopyDocument,
    Check
  },
  props: {
    scope: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    return {
      props,
      getTrackingOfOrder,
      copyToClipboardV2
    }
  }
})
