
import { defineComponent } from 'vue'
import { formatTime, } from '@/utils/dateTime'
import { getFullUrlLattePrint } from '@/utils/image'
import {
  Check,
  SemiSelect,
} from '@element-plus/icons'

export default defineComponent({
  name: 'ColumnExport',
  components: {
    Check,
    SemiSelect,
  },
  props: {
    scope: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    return {
      props,
      formatTime,
      getFullUrlLattePrint
    }
  }
})
