
import { defineComponent } from 'vue'
import { InfoFilled } from '@element-plus/icons'

export default defineComponent({
  name: 'ColumnCustomer',
  components: {
    InfoFilled
  },
  props: {
    scope: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
    }
  },
  setup(props) {
    return {
      props
    }
  }
})
