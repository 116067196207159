
import { defineComponent, reactive, ref, watch } from 'vue'
import { downloadFile } from '@/utils/file'
import { formatTime } from '@/utils/dateTime'
import { getFullUrlLattePrint } from '@/utils/image'
import Pagination from '@/components/common/Pagination.vue'
import axios from '@/services/axios'

export default defineComponent({
  components: {
    Pagination,
  },
  setup() {
    let dialogVisible = ref(false)
    const params = reactive({ page: 1, limit: 10, search: '', status: '' })
    const documents = ref<any>([])
    const loading = ref(false)
    const idImport = ref('')
    const total = ref(0)
    const toggle = (id: string) => {
      loading.value = true
      idImport.value = id
      fetchData()
      dialogVisible.value = !dialogVisible.value
    }
    const close = () => {
      dialogVisible.value = false
    }

    const fetchData = async () => {
      loading.value = true
      try {
        const res = (await axios.get(`import/history/${idImport.value}`, {
          params,
        })) as any
        documents.value = res.data.data
        total.value = res.data.pagination.total
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }
    watch(params, fetchData)

    return {
      toggle,
      dialogVisible,
      close,
      downloadFile,
      formatTime,
      getFullUrlLattePrint,
      documents,
      fetchData,
      loading,
      params,
      total,
    }
  },
})
