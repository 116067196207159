
import { defineComponent } from 'vue'
import { validateOrderAction } from '@/utils/orderUtils'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'
import { UserTypes } from '@/utils/types'
import { ADDRESS_STATUS } from '@/utils/constants'
import { ArrowDown } from '@element-plus/icons'
import { ElMessageBox } from 'element-plus'

export default defineComponent({
  name: 'ColumnActions',
  components: {
    ArrowDown,
  },
  props: {
    scope: {
      type: Object,
      default: () => ({}),
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    state: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { cancelOrders, verifyAddressOrders, ignoreCannotShipOrder } = useOrderRepositories()

    const {
      validateApproved,
      validatePickup,
      validateReject,
      validateCancelShip,
      validateVerifyAddress,
      validateActionWithStatus,
      validateAgencyCancel,
      ALLOW_STATUS_WITH_ACTION,
    } = validateOrderAction()
    const { success, error } = useNotification()

    const verifyAddressOrder = async (ids: any[]) => {
      try {
        const res = (await verifyAddressOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Verify Address order success!`)
          emit('onLoadOrders')
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }
    const onSubmitApprove = (ids: string) => {
      emit('onSubmitApprove', ids)
    }
    const onSubmitCancelShip = (ids: string) => {
      emit('onSubmitCancelShip', ids)
    }

    const onSubmitReject = (ids: string) => {
      emit('onSubmitReject', ids)
    }

    const onUpdateIgnoreCannotShipOrder = async (ids: any[], isIgnore: boolean) => {
      try {
        const res = await ignoreCannotShipOrder({
          ids: ids,
          isIgnoreCannotShip: isIgnore,
        })
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Update order success!`)
          emit('onLoadOrders')
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const onCancelReject = async (ids?: any[]) => {
      try {
        ElMessageBox.prompt('Input cancel reason', 'Cancel order', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Close',
        }).then(async ({ value }) => {
          if (!ids?.length) {
            ids = props.state?.multipleSelection.map((order: any) => order._id)
          }
          let res = await cancelOrders({
            ids: ids,
            content: value,
          })
          if (res?.status === 200 || res?.status === 201) {
            success('Reject order success!')
            emit('onLoadOrders')
          } else {
            error('Error when cancel order')
          }
        })
      } catch (err) {
        error()
      }
    }

    const onClickRequestSeller = () => {
      emit('onClickRequestSeller', props.scope.row)
    }

    const onClickAgencyNote = () => {
      emit('onClickAgencyNote', props.scope.row)
    }
    return {
      props,
      UserTypes,
      ADDRESS_STATUS,
      validateApproved,
      validatePickup,
      validateReject,
      validateCancelShip,
      validateVerifyAddress,
      validateActionWithStatus,
      validateAgencyCancel,
      ALLOW_STATUS_WITH_ACTION,
      verifyAddressOrder,
      onSubmitApprove,
      onSubmitCancelShip,
      onSubmitReject,
      onUpdateIgnoreCannotShipOrder,
      onCancelReject,
      onClickRequestSeller,
      onClickAgencyNote,
    }
  },
})
