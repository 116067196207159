
import { defineComponent } from 'vue'
import { statusTitleMap, } from '@/utils/constants'
import { getOrderStatusColor, } from '@/utils/orderUtils'

export default defineComponent({
  name: 'ColumnStatus',
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      props,
      statusTitleMap,
      getOrderStatusColor
    }
  }
})
