
import { defineComponent } from 'vue'
import { formatterDollars } from '@/utils/formatter'
import { InfoFilled } from '@element-plus/icons'

export default defineComponent({
  name: 'ColumnSellerTeam',
  components: {
    InfoFilled,
  },
  props: {
    scope: {
      type: Object,
      required: true,
    },
    sellerTeam: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const getStoreDomain = (store: any) => {
      let storeDomain = ''
      if (store?.domain && store.domain !== store.appShopUrl) {
        storeDomain = store.domain
      }
      return storeDomain
    }
    return {
      props,
      formatterDollars,
      getStoreDomain,
    }
  },
})
