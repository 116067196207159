import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "uppercase mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Check = _resolveComponent("Check")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_CopyDocument = _resolveComponent("CopyDocument")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "top",
    title: "List tracking number",
    width: "auto",
    trigger: "hover"
  }, _createSlots({
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTrackingOfOrder(
        _ctx.props.scope.row
      ), (trackingNumber, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("p", _hoisted_1, [
            _createTextVNode(_toDisplayString(trackingNumber) + " ", 1),
            _createVNode(_component_el_button, {
              size: "small",
              circle: "",
              title: "Copy to clipboard",
              onClick: ($event: any) => (_ctx.copyToClipboardV2(trackingNumber))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CopyDocument)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]))
      }), 128))
    ]),
    _: 2
  }, [
    (_ctx.props.scope.row[`items`].some(function (i) {
      return !!i.trackingNumber
    })
      )
      ? {
          name: "reference",
          fn: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "text",
              class: "text-success font-bold m-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  size: 25,
                  class: "font-bold"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Check)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        }
      : undefined
  ]), 1024))
}