import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-bold" }
const _hoisted_2 = {
  key: 0,
  class: "custom-text-mini"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_tag, {
        type: _ctx.getAddressStatusColor(_ctx.props.scope.row[_ctx.column.dataIndex]),
        effect: "dark",
        class: "rounded",
        size: "small",
        title: _ctx.addressTitleMap.get(_ctx.props.scope.row[_ctx.column.dataIndex])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.addressTitleMap.get(_ctx.props.scope.row[_ctx.column.dataIndex])), 1)
        ]),
        _: 1
      }, 8, ["type", "title"])
    ]),
    (_ctx.props.scope.row.isIgnoreCannotShip)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString('Can Ship')))
      : _createCommentVNode("", true)
  ], 64))
}